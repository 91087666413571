import { ROUTES, PERMISSIONS } from "../constants";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import InsertLinkOutlinedIcon from "@material-ui/icons/InsertLinkOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import WatchOutlinedIcon from "@material-ui/icons/WatchOutlined";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";

export default [
  {
    title: "院舍管理",
    icon: <HomeWorkOutlinedIcon />,
    children: [
      {
        title: "院舍總覽",
        href: ROUTES.HOME_OVERVIEW,
        permissions: [PERMISSIONS.HOME_OVERVIEW],
      },
      {
        title: "行事曆",
        href: ROUTES.CALENDAR,
        permissions: [PERMISSIONS.CALENDAR],
      },
      {
        title: "院舍管理",
        href: ROUTES.HOME_MANAGEMENT,
        permissions: [PERMISSIONS.HOME_MANAGEMENT],
      },
      {
        title: "床位管理",
        href: ROUTES.BED_MANAGEMENT,
        permissions: [PERMISSIONS.BED_MANAGEMENT],
      },
      {
        title: "用戶群組管理",
        href: ROUTES.ROLE_MANAGEMENT,
        permissions: [PERMISSIONS.ROLE_MANAGEMENT],
      },
      {
        title: "院舍資料",
        href: ROUTES.HOME_INFO,
        permissions: [PERMISSIONS.HOME_INFO],
      },
    ],
  },
  {
    title: "院友管理",
    icon: <PeopleOutlineOutlinedIcon />,
    children: [
      {
        title: "院友總覽",
        href: ROUTES.RESIDENT_MANAGEMENT,
        permissions: [PERMISSIONS.RESIDENT_MANAGEMENT],
      },
      {
        title: "訂位管理",
        children: [
          {
            title: " - 訂位總覽",
            href: ROUTES.RESERVATION_MANAGEMENT,
            permissions: [PERMISSIONS.RESERVATION_MANAGEMENT],
          },
          {
            title: " - 訂金記錄",
            href: ROUTES.DOWN_PAYMENT_RECORD,
            permissions: [PERMISSIONS.DOWN_PAYMENT_RECORD],
          },
        ],
      },
      {
        title: "退院總覽",
        href: ROUTES.DISCHARGE_MANAGEMENT,
        permissions: [PERMISSIONS.DISCHARGE_MANAGEMENT],
      },
      {
        title: "死亡記錄",
        href: ROUTES.DEATH_RECORD,
        permissions: [PERMISSIONS.DEATH_RECORD],
      },
      {
        title: "院友外宿",
        href: ROUTES.LEAVE_RECORD,
        permissions: [PERMISSIONS.LEAVE_RECORD],
      },
      {
        title: "院友統計",
        href: ROUTES.RESIDENT_STATISTICS,
        permissions: [PERMISSIONS.RESIDENT_STATISTICS],
      },
      {
        title: "院友設定",
        href: ROUTES.RESIDENT_SETTING,
        permissions: [PERMISSIONS.RESIDENT_SETTING],
      },
    ],
  },
  {
    title: "會計管理",
    icon: <AccountBalanceWalletOutlinedIcon />,
    children: [
      {
        title: "預設費用",
        href: ROUTES.DEFAULT_EXPENSE,
        permissions: [PERMISSIONS.DEFAULT_EXPENSE],
      },
      {
        title: "院友發票",
        href: ROUTES.RESIDENT_INVOICE,
        permissions: [PERMISSIONS.RESIDENT_INVOICE],
      },
      {
        title: "院舍支出",
        children: [
          {
            title: " - 支出記錄",
            href: ROUTES.HOME_EXPENSE,
            permissions: [PERMISSIONS.HOME_EXPENSE],
          },
          {
            title: " - 付款記錄",
            href: ROUTES.HOME_EXPENSE_RECORD,
            permissions: [PERMISSIONS.HOME_EXPENSE_RECORD],
          },
          {
            title: " - 零用現金管理",
            href: ROUTES.HOME_EXPENSE_PETTY_CASH,
            permissions: [PERMISSIONS.HOME_EXPENSE_RECORD],
          },
          {
            title: " - 零用現金支出",
            href: ROUTES.HOME_EXPENSE_PETTY_CASH_EXPENSE,
            permissions: [PERMISSIONS.HOME_EXPENSE_RECORD],
          },
        ],
      },
      {
        title: "院友按金",
        href: ROUTES.RESIDENT_DEPOSIT,
        permissions: [PERMISSIONS.RESIDENT_DEPOSIT],
      },
      {
        title: "員工薪金",
        href: ROUTES.STAFF_SALARY,
        permissions: [PERMISSIONS.STAFF_SALARY],
      },
      {
        title: "會計設定",
        href: ROUTES.ACCOUNTING_SETTING,
        permissions: [PERMISSIONS.ACCOUNTING_SETTING],
      },
    ],
  },
  {
    title: "藥物管理",
    icon: <InsertLinkOutlinedIcon />,
    children: [
      {
        title: "藥物總覽",
        href: ROUTES.MEDICINE_MANAGEMENT,
        permissions: [PERMISSIONS.MEDICINE_MANAGEMENT],
      },
      {
        title: "藥物報表",
        href: ROUTES.MEDICINE_REPORT,
        permissions: [PERMISSIONS.MEDICINE_REPORT],
      },
      {
        title: "藥物設定",
        href: ROUTES.MEDICINE_SETTING,
        permissions: [PERMISSIONS.MEDICINE_SETTING],
      },
    ],
  },
  {
    title: "醫療記錄",
    icon: <LocalHospitalOutlinedIcon />,
    children: [
      {
        title: "病歷管理",
        href: ROUTES.MEDICAL_RECORD_MANAGEMENT,
        permissions: [PERMISSIONS.MEDICAL_RECORD_MANAGEMENT],
      },
      {
        title: "覆診管理",
        href: ROUTES.RESIDENT_FOLLOW_UP_MANAGEMENT,
        permissions: [PERMISSIONS.RESIDENT_FOLLOW_UP_MANAGEMENT],
      },
      {
        title: "醫療項目",
        href: ROUTES.MEDICAL_ITEM,
        permissions: [PERMISSIONS.MEDICAL_ITEM],
      },
      {
        title: "急症記錄",
        href: ROUTES.RESIDENT_EMERGENCY_RECORD_MANAGEMENT,
        permissions: [PERMISSIONS.RESIDENT_EMERGENCY_RECORD_MANAGEMENT],
      },
      {
        title: "醫療設定",
        href: ROUTES.MEDICAL_SETTING,
        permissions: [PERMISSIONS.MEDICAL_SETTINGS],
      },
    ],
  },
  {
    title: "護理記錄",
    icon: <FavoriteBorderOutlinedIcon />,
    children: [
      {
        title: "生命表徵",
        href: ROUTES.RESIDENT_VITAL_SIGN,
        permissions: [PERMISSIONS.RESIDENT_VITAL_SIGN],
      },
      {
        title: "傷口管理",
        href: ROUTES.WOUND_MANAGEMENT,
        permissions: [PERMISSIONS.WOUND_MANAGEMENT],
      },
      {
        title: "約束物品",
        href: ROUTES.RESIDENT_RESTRAINT_ITEM,
        permissions: [PERMISSIONS.RESIDENT_RESTRAINT_ITEM],
      },
      {
        title: "ICP",
        href: ROUTES.ICP,
        permissions: [PERMISSIONS.ICP],
      },
      {
        title: "護理設定",
        href: ROUTES.NURSING_SETTING,
        permissions: [PERMISSIONS.NURSING_SETTING],
      },
    ],
  },
  {
    title: "員工管理",
    icon: <PermContactCalendarOutlinedIcon />,
    children: [
      {
        title: "員工資料",
        href: ROUTES.STAFF_USERS,
        permissions: [PERMISSIONS.STAFF_USERS],
      },
      {
        title: "編制更表",
        href: ROUTES.ROSTER_RECURRING,
        permissions: [PERMISSIONS.ROSTER_RECURRING],
      },
      {
        title: "更表管理",
        href: ROUTES.ROSTER_MANAGEMENT,
        permissions: [PERMISSIONS.ROSTER_MANAGEMENT],
      },
    ],
  },
  {
    title: "手帶管理",
    icon: <WatchOutlinedIcon />,
    children: [
      {
        title: "手帶總覽",
        href: ROUTES.WRISTBAND_MANAGEMENT,
        permissions: [PERMISSIONS.WRISTBAND_MANAGEMENT],
      },
      {
        title: "手帶設定",
        href: ROUTES.WRISTBAND_SETTING,
        permissions: [PERMISSIONS.WRISTBAND_SETTING],
      },
    ],
  },
  {
    title: "防遊走管理",
    icon: <WatchOutlinedIcon />,
    children: [
      {
        title: "防遊走總覽",
        href: ROUTES.IBEACON_MANAGEMENT,
        permissions: [PERMISSIONS.IBEACON_MANAGEMENT],
      },
      {
        title: "防遊走設定",
        href: ROUTES.IBEACON_SETTING,
        permissions: [PERMISSIONS.IBEACON_SETTING],
      },
    ],
  }
];
